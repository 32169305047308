import { useState, useEffect } from 'react'
import { Box, Heading, Flex, Text } from '@chakra-ui/react'

function App () {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch('https://posts.waynetee.workers.dev/posts')
      const postsResp = await resp.json()
      setPosts(postsResp)
    }

    getPosts().catch(e => console.log(e))
  }, [])

  return (
    <Flex ml='5vw' mt={10} direction='column'>
      <Heading m={3}>Welcome!</Heading>
      {posts.map(post => (
        <Box
          m={3}
          p='5'
          maxW='400px'
          borderWidth='2px'
          rounded='md'
          boxShadow='sm'
        >
          <Text fontSize='xl' fontWeight='semibold'>
            {post.title}
          </Text>
          <Text mt={1} fontSize='md'>
            {post.content}
          </Text>
          <Text mt={2} fontSize='sm' fontWeight='semibold' align='right'>
            -{post.username}
          </Text>
        </Box>
      ))}
    </Flex>
  )
}

export default App
